@import './styles/variables.css';

body {
  margin: 0;
  font-family: var(--font-family-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-family-code);
}

/* Accessibility improvements */
:focus {
  outline: 2px solid var(--color-secondary);
  outline-offset: 2px;
}

/* Basic transitions for interactive elements */
a, button {
  transition: var(--transition-base);
}

/* Common focus states */
a:focus, button:focus {
  outline: 2px solid var(--color-secondary);
  outline-offset: 2px;
}

/* Remove outline for mouse users, keep for keyboard navigation */
:focus:not(:focus-visible) {
  outline: none;
}

/* Ensure focus-visible has proper styling */
:focus-visible {
  outline: 2px solid var(--color-secondary);
  outline-offset: 2px;
}