/* Import CSS variables */
@import './variables.css';

/* Reset and General Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--font-family-primary);
    line-height: var(--line-height-relaxed);
    background-color: var(--color-bg-body);
    color: var(--color-text);
}

/* Utility Classes */
.bg-light {
    background-color: var(--color-bg-light);
}

.bg-gray {
    background-color: var(--color-bg-gray);
}

.bg-primary {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.container {
    width: var(--container-width);
    max-width: var(--container-max-width);
    margin: 0 auto;
}

.container p {
    margin-bottom: var(--spacing-md);
}

/* Layout */
.sections {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-xl);
    text-align: center;
    width: 100%;
}

/* Typography */
h2 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-sm);
}

h3 {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-xs);
}

p {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-sm);
}

.btn {
    font-weight: bold;
    text-decoration: underline;
    transition: var(--transition-base);
}

/* Header Styles */
.sticky-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    color: var(--color-white);
    z-index: var(--z-index-header);
    padding: var(--spacing-sm) 0;
    text-align: center;
    width: 100%;
}

.bg_flare {
    background-color: var(--color-primary);
    background-image: url('../../public/assets/lens-flare.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: var(--shadow-header);
    color: var(--color-white);
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
}

.sticky-header img {
    max-width: 30%;
    margin: var(--spacing-sm) 0;
}

.main_heading {
    font-size: var(--font-size-lg);
    text-transform: uppercase;
    margin-bottom: var(--spacing-sm);
}

/* Language Switcher */
.language-switcher {
    position: absolute;
    top: var(--spacing-sm);
    right: var(--spacing-sm);
}

.language-switcher button {
    background: transparent;
    color: var(--color-white);
    border: 1px solid var(--color-white);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-md);
    cursor: pointer;
    transition: var(--transition-base);
}

.language-switcher button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.language-switcher a {
    color: var(--color-white);
    font-size: var(--font-size-xs);
    font-weight: bold;
    text-decoration: underline;
}

/* Contact Button */
.contact-link {
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-sm);
}

.contact-link button {
    background: #FF3333;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius-md);
    font-weight: bold;
    cursor: pointer;
    transition: var(--transition-base);
}

.contact-link button:hover {
    background: var(--color-white);
    color: var(--color-primary);
}

button[id="open-contact-form"] {
    font-weight: bold;
    text-decoration: underline;
    color: var(--color-warning);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: var(--transition-base);
}

button[id="open-contact-form"]:hover {
    color: var(--color-warning-hover);
}

/* Engines Section */
.engines {
    display: flex;
    justify-content: center;
    gap: var(--spacing-lg);
    margin: var(--spacing-xl);
}

.engine {
    flex: 1;
    background-color: var(--color-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-light);
    padding: var(--spacing-lg);
    transition: transform var(--transition-base), box-shadow var(--transition-base);
}

.engine:hover {
    transform: translateY(-10px);
    box-shadow: var(--shadow-medium);
}

.engine img, .llens img {
    height: 100px;
    margin-bottom: var(--spacing-xs);
}

/* Features Section */
.features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: var(--spacing-lg);
    margin: var(--spacing-xl);
    width: 100%;
}

.feature {
    flex: 1;
    min-width: calc(33.33% - var(--spacing-xl));
    background-color: var(--color-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-light);
    padding: var(--spacing-lg);
    transition: transform var(--transition-base), box-shadow var(--transition-base);
}

.feature:hover {
    transform: translateY(-10px);
    box-shadow: var(--shadow-medium);
}

.feature p {
    font-size: var(--font-size-base);
}

.feature img {
    height: 100px;
    margin-bottom: var(--spacing-xs);
}

/* Supported By Section */
.supported-by {
    width: 100%;
}

.support-logo {
    width: 120px;
    margin: var(--spacing-sm);
    transition: var(--transition-fast);
}

.support-logo:hover {
    transform: scale(1.05);
}

/* Contacts Section */
.contacts {
    color: #a94442;
    font-weight: bold;
    text-transform: uppercase;
    line-height: var(--line-height-base);
}

/* Call to Action (CTA) */
.cta .btn {
    padding: var(--spacing-sm) var(--spacing-lg);
    background-color: var(--color-white);
    color: var(--color-secondary);
    text-transform: uppercase;
    border-radius: var(--border-radius-md);
    transition: var(--transition-base);
}

.cta .btn:hover {
    background-color: var(--color-secondary-dark);
    color: var(--color-white);
}

/* Overlay Styles for Contact Form */
#contact-form-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: var(--z-index-overlay);
    justify-content: center;
    align-items: center;
}

.overlay-content {
    background-color: var(--color-white);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    width: 90%;
    max-width: 500px;
    text-align: left;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
}

.close-btn {
    position: absolute;
    top: var(--spacing-sm);
    right: var(--spacing-sm);
    font-size: 24px;
    cursor: pointer;
    color: var(--color-text);
    transition: var(--transition-fast);
}

.close-btn:hover {
    color: var(--color-secondary);
}

/* Contact Form Styling */
form label {
    display: block;
    margin-bottom: var(--spacing-sm);
    font-size: var(--font-size-md);
}

form input, form textarea {
    width: 100%;
    padding: var(--spacing-sm);
    margin-bottom: var(--spacing-lg);
    border: 1px solid #ccc;
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-base);
}

form button[type="submit"] {
    padding: var(--spacing-sm) var(--spacing-lg);
    background-color: var(--color-secondary);
    color: var(--color-white);
    border: none;
    border-radius: var(--border-radius-md);
    cursor: pointer;
    transition: var(--transition-base);
}

form button[type="submit"]:hover {
    background-color: var(--color-secondary-dark);
}

form button[type="submit"]:disabled {
    background-color: var(--color-text-lighter);
    cursor: not-allowed;
}

/* Success and Error Messages */
.success-message {
    background-color: var(--color-success-bg);
    border: 1px solid var(--color-success-border);
    color: var(--color-success);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-sm);
    margin-bottom: var(--spacing-lg);
}

.error-message {
    background-color: var(--color-error-bg);
    border: 1px solid var(--color-error-border);
    color: var(--color-error);
    padding: var(--spacing-sm);
    border-radius: var(--border-radius-sm);
    margin-bottom: var(--spacing-md);
}

/* Footer */
footer {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: var(--spacing-md);
    text-align: center;
    width: 100%;
}

/* Responsive Design */
@media (max-width: 992px) {
    .features, .engines {
        gap: var(--spacing-md);
    }
    
    .feature, .engine {
        min-width: calc(50% - var(--spacing-lg));
    }
}

@media (max-width: 768px) {
    body {
        line-height: var(--line-height-tight);
    }
    
    .container {
        width: var(--container-mobile-width);
        padding: 0 var(--spacing-sm);
    }
    
    .section {
        padding: var(--spacing-md) var(--spacing-xs);
    }
    
    h2 {
        font-size: var(--font-size-lg);
    }
    
    .features {
        flex-direction: column;
        align-items: center;
    }

    .feature {
        width: 100%;
        min-width: unset;
        margin-bottom: var(--spacing-md);
    }
    
    .engines {
        flex-direction: column;
        align-items: center;
    }

    .engine {
        width: 100%;
        min-width: unset;
        margin-bottom: var(--spacing-md);
    }

    .main_heading {
        font-size: var(--font-size-base);
    }

    .language-switcher {
        top: var(--spacing-xs);
        right: var(--spacing-xs);
    }

    .language-switcher button {
        font-size: var(--font-size-xs);
        padding: var(--spacing-xs) var(--spacing-sm);
    }

    .sticky-header img {
        max-width: 60%;
    }

    .header-content {
        padding: var(--spacing-xs);
    }

    .contact-link button {
        font-size: var(--font-size-sm);
        padding: var(--spacing-xs) var(--spacing-sm);
    }

    .engine img, .feature img {
        height: 70px;
    }

    .overlay-content {
        width: 95%;
        padding: var(--spacing-md);
        max-height: 85vh;
    }

    form input, form textarea {
        padding: var(--spacing-sm);
        margin-bottom: var(--spacing-md);
    }
}

@media (max-width: 576px) {
    .feature, .engine {
        padding: var(--spacing-sm);
    }
    
    .section {
        padding: var(--spacing-sm);
    }
    
    .features, .engines {
        margin: var(--spacing-md);
    }
    
    h3 {
        font-size: var(--font-size-base);
    }
    
    p {
        font-size: var(--font-size-base);
    }
}