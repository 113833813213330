:root {
  /* Color Palette */
  --color-primary: #033946;
  --color-primary-light: #0a5369;
  --color-secondary: #007bff;
  --color-secondary-dark: #0056b3;
  --color-text: #333333;
  --color-text-light: #666666;
  --color-text-lighter: #999999;
  --color-white: #ffffff;
  --color-bg-light: #f4f4f4;
  --color-bg-gray: #e9ecef;
  --color-bg-body: #f9f9f9;
  --color-error: #a94442;
  --color-error-bg: #f2dede;
  --color-error-border: #ebccd1;
  --color-success: #3c763d;
  --color-success-bg: #dff0d8;
  --color-success-border: #d6e9c6;
  --color-warning: #FF0000;
  --color-warning-hover: #FFF000;

  /* Typography */
  --font-family-primary: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --font-size-xs: 0.8rem;
  --font-size-sm: 0.9rem;
  --font-size-base: 1rem;
  --font-size-md: 1.1rem;
  --font-size-lg: 1.5rem;
  --font-size-xl: 2rem;
  --line-height-tight: 1.3;
  --line-height-base: 1.5;
  --line-height-relaxed: 1.7;

  /* Spacing */
  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --spacing-xl: 30px;

  /* Layout */
  --container-width: 60%;
  --container-max-width: 1000px;
  --container-mobile-width: 90%;
  --border-radius-sm: 4px;
  --border-radius-md: 5px;
  --border-radius-lg: 10px;

  /* Shadows */
  --shadow-light: 0 0 20px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 10px 20px rgba(0, 0, 0, 0.15);
  --shadow-header: 0 10px 20px rgba(0, 0, 0, 0.3);

  /* Z-index */
  --z-index-header: 1000;
  --z-index-overlay: 2000;

  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-base: 0.3s ease;

  /* Breakpoints */
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}
